'use client';

import { datadogRum, type DefaultPrivacyLevel } from '@datadog/browser-rum';
import { datadogLogs, LogsEvent } from '@datadog/browser-logs';
import { loadLazyScript } from '@/app/utils/browser';

if (process.env.NEXT_PUBLIC_DATADOG_ACTIVE) {
  if (typeof window !== 'undefined') {
    (window as any).motivatedBuildVersion =
      process.env.NEXT_PUBLIC_BUILD_VERSION;
  }

  const allowedTracingDomains =
    process.env.NEXT_PUBLIC_DATADOG_TRACING_ALLOWED_DOMAINS?.split(',');

  const initDatadog = () => {
    datadogRum.init({
      applicationId: String(process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID),
      clientToken: String(process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN),
      site: 'datadoghq.com',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
      env: process.env.NEXT_PUBLIC_DATADOG_ENV,
      version: process.env.NEXT_PUBLIC_BUILD_VERSION,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: Number(
        process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE,
      ),
      sessionReplaySampleRate: Number(
        process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE,
      ),
      trackUserInteractions: Boolean(
        process.env.NEXT_PUBLIC_DATADOG_TRACK_USER_INTERACTIONS,
      ),
      trackResources: Boolean(process.env.NEXT_PUBLIC_DATADOG_TRACK_RESOURCES),
      trackLongTasks: Boolean(process.env.NEXT_PUBLIC_DATADOG_TRACK_LONG_TASKS),
      defaultPrivacyLevel: process.env
        .NEXT_PUBLIC_DATADOG_DEFAULT_PRIVACY_LEVEL as DefaultPrivacyLevel,
      trackSessionAcrossSubdomains: true,
      allowedTracingUrls: [
        (url) =>
          allowedTracingDomains?.some((domain) => url.includes(domain)) ??
          false,
      ],
    });

    datadogLogs.init({
      clientToken: String(process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN),
      site: 'datadoghq.com',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
      env: process.env.NEXT_PUBLIC_DATADOG_ENV,
      version: process.env.NEXT_PUBLIC_BUILD_VERSION,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100,
      trackSessionAcrossSubdomains: true,
    });
  };

  loadLazyScript(initDatadog);
}

export default function DatadogInit() {
  return null;
}
