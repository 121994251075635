'use client';
import * as React from 'react';
import { saveLandingPage, saveReferrer, saveUtmSource } from '@/app/utils/auth';
import { CLICK_EVENT, Mixpanel } from '@/app/utils/mixpanel';

const ScriptRunnerContext = React.createContext(null);

/**
 * this context only runs scripts when a page is rendered
 */
const ScriptRunnerProvider = ({ children }: React.PropsWithChildren) => {
  React.useEffect(() => {
    // tracks clicks with mixpanel
    const trackClick = (event: MouseEvent) => {
      if (!event.target) {
        return;
      }
      const trackedElement = (event.target as HTMLElement).closest(
        '[data-mixaction]',
      );
      if (!trackedElement) {
        return;
      }

      try {
        const parsed = JSON.parse(
          atob(trackedElement.getAttribute('data-mixaction')!),
        );

        Mixpanel.track(CLICK_EVENT, {
          ...parsed,
          // extra
        });
      } catch (e) {
        // TODO log
        console.log('Failed to parse', e);
      }
    };
    document.addEventListener('click', trackClick);

    // saves a cookie with the first user visited page on website
    saveReferrer(document.referrer);
    saveLandingPage(window.location.href);
    saveUtmSource(window.location.search);

    return () => {
      document.removeEventListener('click', trackClick);
    };
  }, []);

  return (
    <ScriptRunnerContext.Provider value={null}>
      {children}
    </ScriptRunnerContext.Provider>
  );
};

export { ScriptRunnerProvider };
