'use client';

import { createContext, type ReactNode } from 'react';

import type { ConfigurationPresetAttributes } from './types';

interface ConfigurationPresetProviderProps {
  children: ReactNode;
  value: ConfigurationPresetContextData;
}

export interface ConfigurationPresetContextData {
  attributes: ConfigurationPresetAttributes;
}

export const ConfigurationPresetContext = createContext<
  ConfigurationPresetContextData | undefined
>(undefined);

export default function ConfigurationPresetProvider({
  children,
  value,
}: ConfigurationPresetProviderProps) {
  return (
    <ConfigurationPresetContext.Provider value={value}>
      {children}
    </ConfigurationPresetContext.Provider>
  );
}
